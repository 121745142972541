<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-icon class="mr-3">mdi-account-group</v-icon>
            Clientes
            <v-spacer></v-spacer>
            <v-btn outlined :to="{ name: 'personCreate' }">
              <v-icon left>mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col lg="12" md="12">
        <v-card class="text-center">
          <v-alert type="warning" outlined v-show="!persons">
            Nenhum cliente encontrado.
          </v-alert>
          <v-data-table v-model="selected" :headers="headers" :items="persons">
            <template v-slot:item.birthdate="{ item }">
              {{ item.birthdate }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :to="{
                      name: 'personsEdit',
                      params: { id: item.id }
                    }"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { PERSONS_GET } from "@/store/actions/person.type";
import { PERSON_RESET_STATE } from "@/store/mutations/person.type";

export default {
  name: "Persons",
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(PERSON_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(PERSONS_GET, to.params.id);
    return next();
  },
  data: () => ({
    selected: [],
    headers: [
      { text: "Nome", value: "name", align: "center" },
      { text: "CPF", value: "cpf", align: "center" },
      { text: "Telefone", value: "phone", align: "center" },
      { text: "Data de nascimento", value: "birthdate", align: "center" },
      { text: "Editar", value: "actions", sortable: false }
    ]
  }),
  computed: {
    ...mapGetters(["persons"])
  }
};
</script>
